// noinspection GraphQLUnresolvedReference

import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import SliceZone from '../components/SliceZone'
import { RichText } from 'prismic-reactjs'

import { Box } from '@chakra-ui/react'
import NewsletterModal from '../components/NewsletterModal'

import HomeHero from '../components/HomeHero'

const Homepage = ({ data, pageContext }) => {
  if (!data) return null

  const projects = data.projects.edges
  const commitments = data.commitments.edges

  // console.log( 'projects', projects, 'commitments', commitments, 'data', data )

  const homepageContent = data.prismic.allHomepages.edges[0]
  if (!homepageContent) return null
  const homepage = homepageContent.node

  const homepagePosts = data.prismic.allPosts.edges
  if (!homepagePosts) return null
  const posts = homepagePosts

  const homepageBooks = data.prismic.allBooks.edges
  if (!homepageBooks) return null
  const books = homepageBooks

  const topMenuContent = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node
  const showNewsletterForm = ( ) => {
    setNewsletterFormVisible( true )
  }
  const [isNewsletterFormVisible, setNewsletterFormVisible] = useState(false);

// console.log('homepagePosts', homepagePosts )


  return (
    <>
      <Helmet>
        <title>{RichText.asText(homepageContent.node.seo_title)}</title>
        <meta name="description" content={RichText.asText(homepageContent.node.seo_description)} />
        <meta property="og:title" content={RichText.asText(homepageContent.node.seo_title)} />
        <meta property="og:image" content={homepageContent.node.sharing_image.url} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="alternate" hreflang="pt" href="https://www.vitaly-malkin.com/pt-pt" />
        <link rel="alternate" hreflang="en" href="https://www.vitaly-malkin.com/en-gb" />
        <link rel="alternate" hreflang="de" href="https://www.vitaly-malkin.com/de-de" />
        <link rel="alternate" hreflang="x-default" href="https://www.vitaly-malkin.com" />
      </Helmet>
      <Layout
        topMenu={topMenu}
        activeDocMeta={homepage._meta}
        lang={pageContext.langIso}
        color="dark"
        headerPosition="overlap"
      >
        { isNewsletterFormVisible ? <NewsletterModal onClose={ () => { setNewsletterFormVisible( false ) } } /> : null }
        <HomeHero content={ homepage } data={data.hp.data} />
        {/* <Box
          display='none'
          w={{ base: '100vw', lg: 'auto' }}
          position={{ base: 'relative' }}
          py={{ lg: '2rem' }}
          background={'url('+bg+')'}
          backgroundRepeat='repeat-x'
        >
          <Grid
            templateColumns={{ base: '100%', lg: '330px 1fr' }}
            maxW="1030px"
            mx="auto"
          >
            <Box>
              <Image
                w="100%"
                h={{ base: 'calc(100vh - 50px)', lg: 'auto' }}
                objectFit="cover"
                src={homepageContent.node.hero_image.phone.url}
                alt={homepage.hero_image.alt ? homepage.hero_image.alt : 'Vitaly Malkin'}
              />
            </Box>
            <Box
              position={{ base: "absolute", lg: 'initial' }}
              background={{ base: 'linear-gradient(0deg, rgba(251,134,0,1) 0%, rgba(251,134,0,0) 100%)', lg: 'none' }}
              bottom="0"
              p={{ base: "2rem", lg: "0" }}
            >
              <Heading
                as="h1"
                transform={{ lg: 'translateX(-50px)' }}
                fontSize={{ base: "20px", lg: "42px" }}
                fontWeight="900"
                color={{ base: "white", lg: "inherit" }}
                py={{ base: 0, lg: '3rem' }}
                pt={{ base: '15rem', lg: '3rem' }}
                minH={{ lg:'360px' }}
              >
                {RichText.asText(homepageContent.node.title)}
              </Heading>

              <Box
                p={{ lg: "2.5rem" }}
              >
                 <Heading
                  as="p"
                  fontSize={{ base: '16px', lg: "20px" }}
                  color={{ base: "white", lg: "brand.orange" }}
                  fontFamily="Open Sans"
                  mb="2rem">
                  {RichText.asText(homepageContent.node.sub_title)}
                </Heading>
                <NewsletterForm
                  color='orange'
                />
              </Box>

              <Box
                display='none'
                p={{ lg: "2.5rem" }}>
                <Heading
                  as="p"
                  fontSize={{ base: '16px', lg: "20px" }}
                  textAlign={{ lg:"center" }}
                  color={{ base: "white", lg: "brand.orange" }}
                  fontFamily="Open Sans"
                  mb="2rem">
                  {RichText.asText(homepageContent.node.sub_title)}
                </Heading>
                <SimpleGrid columns={{ base: 1, lg: 2 }} gap="1rem">
                  <Button
                    backgroundColor={{ base: 'white', lg: "brand.orange" }}
                    whiteSpace= 'pre-wrap'
                    px="1rem"
                    minH='56px'
                    border="solid 1px"
                    borderColor="transparent"
                    borderRadius="1px"
                    borderRadius="1px"
                    color={{ base: 'brand.orange', lg: "brand.cream" }}
                    textTransform="uppercase"
                    // py='1.5rem'
                    py='.5rem'
                    fontFamily="Open Sans"
                    fontWeight="700"
                    boxShadow="sm"
                    _hover={{
                      border: "solid 1px",
                      borderColor: "brand.orange",
                      color: "brand.orange",
                      backgroundColor: "brand.cream"
                    }}
                    onClick={ showNewsletterForm }
                  >
                    <FormattedMessage id="main.subscribe_to_newsletter" />
                  </Button>
                </SimpleGrid>
              </Box>
            </Box>
          </Grid>
        </Box> */}
        <Box py={{ lg: '3rem' }}>
          {/* <pre>
            { JSON.stringify( homepage.body, null, 2 )}
          </pre> */}
          <SliceZone
            slices={homepage.body}
            posts={posts}
            books={books}
            projects={projects}
            commitments={commitments}
          />
        </Box>
      </Layout>
    </>
  )
}

export const query = graphql`
query homepageQuery($lang: String) {


  posts: allPrismicPost(filter:{ lang: { eq: $lang}}){
    edges{
      node{
        id
        uid
        prismicId
        lang
        type
        data{
          is_visible
          title{ text }
          intro{ text raw }
          featured_image{ fixed{ src }}
        }
      }
    }
  }

  books: allPrismicBook(filter:{ lang: { eq: $lang}}){
    edges{
      node{
        uid
        id
        prismicId
        lang
        type
        data{
          is_visible
          title{ text }
          intro{ text raw }
          cover{ fixed{ src }}
        }
      }
    }
  }

  projects: allPrismicProject(filter:{ lang: { eq: $lang}}){
    edges{
      node{
        uid
        id
        prismicId
        lang
        type
        data{
          is_visible
          title{ text }
          intro{ text raw }
          featured_image{ fixed{ src }}
        }
      }
    }
  }

  commitments: allPrismicCommitment(filter:{ lang: { eq: $lang}}){
    edges{
      node{
        uid
        id
        prismicId
        lang
        type
        data{
          is_visible
          title{ text }
          intro{ text raw }
          featured_image{ fixed{ src }}
        }
      }
    }
  }
  
  hp:prismicHomepage(lang: {eq: "fr-fr"}) {
    data {
      hero_image {
        fixed(width: 700) {
          src
        }
      }
    }
  }

  prismic {
    allHomepages(lang: $lang) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          sub_title
          hero_image
          seo_title
          seo_description
          sharing_image
          book_button_target{
            ... on PRISMIC__Document{
              _meta {
                uid
                type
                lang
                alternateLanguages {
                  lang
                  type
                  uid
                }
              }
            }
          }
          body {
            ... on PRISMIC_HomepageBodyEmail_signup {
              type
              label
              primary {
                section_title
                description
                input_label
                input_placeholder
                button_text
              }
            }
            ... on PRISMIC_HomepageBodyFull_width_image {
              type
              label
              primary {
                image
              }
            }
            ... on PRISMIC_HomepageBodyInfo_with_image {
              type
              label
              primary {
                featured_image
                section_title
                text
              }
            }
            ... on PRISMIC_HomepageBodyText_info {
              type
              label
              primary {
                section_title
                left_column_text
                right_column_text
              }
            }
            ... on PRISMIC_HomepageBodyAbout_intro {
              type
              label
							primary{
                first_word
                intro
              }
            }
            ... on PRISMIC_HomepageBodyTitle_only{
              type
              label
              primary{
                title_only_content
              }
            }
            ... on PRISMIC_HomepageBodyBooks {
              type
              label
            }
            ... on PRISMIC_HomepageBodyProjects {
              type
              label
            }
            ... on PRISMIC_HomepageBodyCommitments {
              type
              label
            }
            ... on PRISMIC_HomepageBodyLatest_posts {
              type
              label
              primary{
                show_thumbnails
              }
            }
          }
        }
      }
    }
    allPosts (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          featured_image
          is_visible
        }
      }
    }
    allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          cover
          amazon_link{
            _linkType
            ... on PRISMIC__ExternalLink{
              url
            }
          }
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Post {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default Homepage
