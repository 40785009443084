import React, { useState } from 'react'
import { useIntl } from 'react-intl';

import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Box, Button, Flex, FormLabel, FormHelperText, Grid, Input, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

import Messages from '../locales/messages'

import ReactGA from 'react-ga'
ReactGA.initialize( process.env.GA_ID )

const NewsletterFormHome = ( props ) => {
    const intl = useIntl()
    const { color } = props
    const [isEmailRecord, setEmailRecord] = useState(false);


    const submitNewsletterForm = (e) => {
        console.log( 'publish in MC' )
        e.preventDefault()
        let email = e.target.querySelector('input').value
        console.log(email)
        console.log('submit')

        e.preventDefault();
        addToMailchimp(email ,
                // {lang:'fr', 'book':'book_1'}
                {
                    BOOK: 'generic',
                    LANG: intl.locale
                }
            ) // listFields are optional if you are only capturing the email address.
            .then(data => {
                ReactGA.event({
                    category: 'Newsletter',
                    action: 'Subscribe',
                    label: 'Footer'
                  });
                if (data.result === 'success') {
                    setEmailRecord(true)
                }
                if (data.result === 'error') {
                    setEmailRecord(true)
                }
            })
            .catch(() => {

            })
    }
    return (
        <Box
            as="form"
            onSubmit={(e) => { submitNewsletterForm(e) }}
            mt={{ base:5, lg: 10 }}
        >   
            <FormLabel
                color={{ base:'white', lg:'gray.500' }}
                fontSize={{ base: 12, lg:14 }}
            >
                <FormattedMessage id='main.receive.news.for.free' />&nbsp;:
            </FormLabel>
            <Grid
                wrap="wrap"
                borderRadius='sm'
                gridTemplateColumns={{ base: '100%', lg:'60% 40%'}}
                border={{ base:'solid 2px'}}
                borderColor='brand.orange'
                gap='0'

            >
                {!isEmailRecord ?
                    <>
                        <Box
                            // mr={{ base:0, lg: "1rem"}}
                            w={{ base:'100%', lg:'auto'}}
                            mb={{ base:'0', lg:'auto'}}
                        >
                            <Input
                                borderRadius='0'
                                type="email"
                                required
                                id="email"
                                borderRight='none'
                                placeholder={ Messages[intl.locale]? Messages[intl.locale]['footer.your_email'] : 'Email' }
                                backgroundColor={{ base:'white', lg:'white' }}
                                color={{ base:'white', lg:'gray.700' }}
                                // minW="250px"
                                _focus={{
                                    outline:'none'
                                }}
                            />
                        </Box>
                        <Box
                            w={{ base:'100%', lg:'auto'}}
                        >
                            <Button
                                boxShadow='none'
                                outline='none'
                                transform={{ base: 'none', lg:'translateX(1px)' }}
                                fontSize='14px'
                                type="submit"
                                whiteSpace='pre-wrap'
                                textAlign='center'
                                backgroundColor={ 'brand.orange' }
                                color={ 'white' }
                                borderRadius='0'
                                borderLeft='solid 2px'
                                borderLeftColor='transparent'
                                fontFamily="Open sans"
                                width='100%'
                                textTransform="uppercase"
                                _hover={{
                                    backgroundColor: 'white',
                                    color: 'brand.orange',
                                    borderLeftColor: 'brand.orange'

                                }}
                            >
                                <FormattedMessage id="main.subscribe" />
                            </Button>
                        </Box>
                    </>
                    :
                    <Text
                        fontSize={{ base:12, lg: 16 }}
                        p={{ base: 2, lg: 2 }}
                        color={{ base:'white', lg:'gray.500'}}
                    >
                        <FormattedMessage id="footer.subscrive_to_vitaly_newsletter_success" />
                    </Text>
                }
            </Grid>
            <Text
                mt={ 2 }
                color={{ base:'white', lg:'gray.500' }}
                textTransform='uppercase'
                fontSize={{ base: 12, lg:12 }}
            >
                <FormattedMessage id="main.newsletter.notice" />
            </Text>
        </Box>
    )
}

export default NewsletterFormHome