import React from 'react'
import {
    Box,
    Flex,
    Heading,
    Image
} from '@chakra-ui/react'
import quoteImage from '../images/quote.svg'
import NewsletterFormHome from './NewsletterFormHome'

const HomeHero = ( { content, data } ) => {
    return(
        <Box
            maxW='1030px'
            mx='auto'
            py={{ base: 0, lg:'1rem' }}
            h='565px'
            my={{ base:0, lg:10 }}
            position='relative'
            w={{ base:'100vw', lg:'100%' }}
            h={{ base:'100vh', lg:'auto' }}
        >
            <Box
                position='absolute'
                zIndex='banner'
                w={{ base: '100%', lg:'auto'}}
                h={{ base: '100%', lg:'auto'}}
            >
                <Image
                    borderRadius='2px'
                    filter={{ base: "brightness(0.8)", lg:"brightness(1)" }}
                    w={{ base: '100%', lg:'350px'}}
                    h={{ base: '100%', lg:'auto'}}
                    objectFit='cover'
                    src={ data.hero_image.fixed.src }

                />
            </Box>
            <Flex
                align={{ base: 'flex-end', lg:'center' }}
                h={{ base:'100vh', lg:'565px' }}
                maxW='1030px'
                w={{ base:'100vw', lg:'100%' }}
            >
                 <Box
                    borderRadius={{ base:0, lg:'md' }}
                    ml={{ base: 0, lg:'330px' }}
                    h={{ base:'auto', lg:'400px' }}
                    bgPos={{ base:'10% 10%', lg:'top left' }}
                    bgRepeat='no-repeat'
                    bgColor={{ base: 'none', lg:'gray.50' }}
                    bgSize={{ base:'auto', lg:'200px 200px' }}
                    bgGradient={{ base:"linear(to-b, rgba(50,50,50,0), rgba(50,50,50,0.85))", lg:'none' }}
                    p={{ base:4 , lg:20 }}
                    pt={{ base:30 , lg:'8rem' }}
                    transform={{ base: 'none', lg:'translateX(-10%)' }}
                    zIndex='banner'
                >
                    <Box
                        bgImage={{ base:'none', lg:`url(${quoteImage})`}}
                        bgRepeat='no-repeat'
                        bgSize='230px 230px'
                        w='230px'
                        h='230px'
                        position='absolute'
                        zIndex='base'
                        top='2rem'
                        left='2rem'
                    />
                    <Heading
                        as='h1'
                        color={{ base:'white', lg:'gray.900' }}
                        fontFamily='Vesper Libre'
                        position='relative'
                    >
                        { content.title[0].text }
                    </Heading>
                    <NewsletterFormHome />
                </Box>
            </Flex>
        </Box>
    )
}

export default HomeHero
